@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import '~handsontable/dist/handsontable.min.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Merriweather:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

@import '~ag-grid-community/dist/styles/ag-grid.min.css';
@import '~ag-grid-community/dist/styles/ag-theme-material.min.css';
/* latin-ext */

$my-mat-red: (
  50: #f0e7f2,
  100: #d9c4de,
  200: #bf9cc8,
  300: #a574b2,
  400: #9257a2,
  500: #7f3991,
  600: #773389,
  700: #6c2c7e,
  800: #622474,
  900: #4f1762,
  A100: #e49cff,
  A200: #d669ff,
  A400: #c936ff,
  A700: #c21dff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$md-greenish: (
  50: #e8f5f2,
  100: #c5e5e0,
  200: #9ed4cb,
  300: #77c2b6,
  400: #5ab5a6,
  500: #3da896,
  600: #37a08e,
  700: #2f9783,
  800: #278d79,
  900: #1a7d68,
  A100: #b6ffee,
  A200: #83ffe3,
  A400: #50ffd7,
  A700: #36ffd1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$md-orange: (
  50: #fcf4e2,
  100: #f8e3b6,
  200: #f4d186,
  300: #efbf55,
  400: #ebb130,
  500: #e8a30c,
  600: #e59b0a,
  700: #e29108,
  800: #de8806,
  900: #d87703,
  A100: #ffffff,
  A200: #ffe6cd,
  A400: #ffcc9a,
  A700: #ffbf80,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$custom-typography: mat.define-typography-config(
  $font-family: "'Open Sans', sans-serif",
  $headline: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(16px, 24px, 500),
);

// Override typography for a specific Angular Material components.
@include mat.checkbox-typography($custom-typography);
// Override typography for all Angular Material, including mat.base-typography and all components.

//@include mat.all-component-themes($kids-theme);

@include mat.core($custom-typography);
$primary: mat.define-palette($md-orange);
$accent: mat.define-palette($md-greenish);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    typography: $custom-typography,
  )
);
@include mat.all-component-themes($theme);

$mq-mobile-portrait: 320px !default;
$mq-mobile-landscape: 480px !default;
$mq-tablet-portrait: 768px !default;
$mq-tablet-landscape: 1024px !default;
$mq-desktop: 1382px !default;

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  color: white;
  font-weight: bold;
}

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked:after {
  color: #e8a30c !important;
}
.row-selected-opacity .ag-row-selected:before {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

html {
  scroll-behavior: smooth;
  line-height: 1.25;
}

body {
  margin: 0px;
  font-family: /* Poppins, */ Roboto, Raleway, 'Open Sans', sans-serif !important;
  background-color: #f2f2f2;
  background-color: #f2f2f2;
  font-size: 14px;
}

strong {
  font-weight: bold;
}

a:-webkit-any-link {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-line: underline;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

h1 {
  display: block;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 {
  font-weight: bold;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

h3 {
  font-weight: bold;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

*,
::before,
::after {
  box-sizing: initial;
  border-width: initial;
  border-style: initial;
  border-color: initial;
}

*,
:after,
:before {
  border: 0 solid #e0e0e0;
}

img {
  display: initial;
  vertical-align: initial;
}

.mat-icon {
  overflow: visible !important;
}

.logo {
  width: 170px;
  height: 41px;
  margin-right: 20px;
  margin-left: 70px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../src/assets/logo_betrail_small.png'); // fallback
  background-image: url('../src/assets/logo_betrail_run.svg');
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}
.search {
  width: 250px;
  height: 20px;
  color: white;
  padding: 6px 15px 6px 30px;
  margin: 3px;
  background: url('./assets/icons/magnifier.png') no-repeat 10px 10px;
  background-size: 14px 14px;
  &:focus {
    outline: 0;
  }
}
// Both portrait and landscape
@mixin mobile-only {
  @media (max-width: $mq-mobile-landscape) {
    @content;
  }
}
.center {
  margin: auto;
  max-width: 467px;
  padding-bottom: 15px;
}
body {
  margin: 0px;
  font-family: /* Poppins, */ Roboto, 'Open Sans', sans-serif;
  background-color: #f2f2f2;
  background-color: #f2f2f2;
}

.mat-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none !important;
  text-decoration-line: none !important;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

// End of new Style
.menuLabel {
  @include mobile-only() {
    display: none;
  }
}
md-icon {
  padding: 3px;
}
.logo {
  width: 320px;
}
.orga-label {
  font-size: 5em;
  color: #525e64;
  float: right;
}
.login-dark {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}
.login-dark form {
  max-width: 320px;
  width: 90%;
  background-color: #1e2833;
  padding: 40px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}
.login-dark .illustration {
  text-align: center;
  padding: 15px 0 20px;
  font-size: 100px;
  color: #2980ef;
}
.login-dark form .form-control {
  background: none;
  border: none;
  border-bottom: 1px solid #434a52;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
}
.login-dark form .btn-primary {
  background: #214a80;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 26px;
  text-shadow: none;
  outline: none;
}
.login-dark form .btn-primary:hover,
.login-dark form .btn-primary:active {
  background: #214a80;
  outline: none;
}
.login-dark form .new-account {
  display: block;
  text-align: center;
  font-size: 18px;
  opacity: 0.9;
  text-decoration: none;
}
.login-dark form .forgot {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}
.login-dark form .forgot:hover,
.login-dark form .forgot:active {
  opacity: 1;
  text-decoration: none;
}
.login-dark form .btn-primary:active {
  transform: translateY(1px);
}
.main-container {
  height: 100%;
  margin: 30px;
  padding: 15px;
  background-color: #e0e0e0;
  border-radius: 4px;
}
.excelheader {
  background-color: rgb(250, 245, 239) !important;
  border: solid 1px grey !important;
  border-bottom: 6px solid orange !important;
  &:hover {
    cursor: pointer;
    background-color: rgba(139, 139, 139, 0.11) !important;
  }
}
.validheader {
  border-bottom: 6px solid green !important;
  background-color: rgb(241, 252, 243) !important;
}
.toChoose {
  text-transform: uppercase;
  background-color: rgb(248, 219, 137) !important;
}
.validation_row_error {
  text-transform: uppercase;
  // color: red !important;
  background-color: rgb(253, 236, 236) !important;
}
.validation_error {
  text-transform: uppercase;
  color: red !important;
  background-color: rgb(253, 197, 197) !important;
}
.withDefault {
  text-transform: uppercase;
  background-color: rgb(255, 235, 179) !important;
}
.chooseRunner {
  text-transform: uppercase;
  background-color: rgb(220, 255, 179) !important;
}
.newRunner {
  text-transform: uppercase;
  background-color: rgb(240, 235, 221) !important;
}
.readOnlyHeader {
  border-bottom: 6px solid rgb(180, 180, 180) !important;
  background-color: rgb(219, 219, 219) !important;
  &:hover {
    cursor: default;
    background-color: rgb(219, 219, 219) !important;
  }
}
.leaflet-marker-icon.marker-cluster {
  width: 26px !important;
  height: 26px !important;
  line-height: 26px;
  text-align: center;
  font-weight: bold;
  opacity: 0.85;
  background: transparent;
  border: 4px solid rgba(232, 163, 12, 0.5);
  border-radius: 50%;
  color: white;
  div {
    background: rgb(232, 163, 12);
    border-radius: 50%;
  }
}
.leaflet-popup-content-wrapper {
  padding: 0 !important;
  overflow: hidden !important;
}
.leaflet-popup-content {
  margin: 0 !important;
  /* line-height: 0; */
}
.noUi-horizontal .noUi-handle {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  border: none;
  background: #e8a30c;
  top: -8px !important;
  right: -10px !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
.noUi-horizontal .noUi-handle:after,
.noUi-horizontal .noUi-handle:before {
  display: none !important;
}
.noUi-target {
  border: 0 !important;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0.38) !important;
  height: 3px !important;
}
.noUi-connect {
  background: #e8a30c !important;
}
.noUi-horizontal .noUi-tooltip {
  border: 0 !important;
  font-size: 80% !important;
  padding: 0 !important;
}
.mat-autocomplete-panel {
  max-height: calc(100% - 80px) !important;
  overflow-y: hidden !important;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  border-radius: 30px !important;
  border-radius: 7px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.mat-optgroup .mat-option:not(.mat-option-multiple) {
  padding-left: 16px !important;
}
.mat-toolbar-multiple-rows {
  min-height: 0px !important;
}
#appLoading {
  width: 100vw;
  height: 100vh;
  text-align: center;
  line-height: 100vh;
}
.race-menu-container {
  .mat-button-toggle-label-content {
    line-height: 32px !important;
    padding: 0 10px;
  }
  a.selected {
    .mat-button-toggle-label-content {
      color: grey;
      color: rgba(0, 0, 0, 0.54);
      font-weight: bold;
    }
  }
}
.mat-button {
  font-family: /* Poppins, */ Roboto, Tahoma, Raleway, Verdana, Arial, sans-serif !important;
}

.mat-select-trigger {
  height: 1.15em !important;
  font-family: /* Poppins, */ Roboto, Arial, 'Open Sans', sans-serif;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0.4em !important;
  margin: 0 !important;
  margin-bottom: 0.3em !important;
}
.mat-stepper-vertical {
  background: transparent !important;
}
.mat-vertical-content {
  padding: 24px !important;
}
.mat-vertical-stepper-header {
  padding: 1em !important;
}
.mat-step-label-selected {
  font-size: 16px !important;
  font-weight: 700 !important;
}
.mat-step-icon,
.mat-step-icon-not-touched {
  border-radius: 50%;
  height: 2em !important;
  width: 2em !important;
}
.mat-vertical-content-container {
  margin-left: 2em !important;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  overflow: visible !important;
}
.mat-slide-toggle {
  margin-bottom: 0.5em;
}
.mat-checkbox-label {
  // NEW
  white-space: initial !important;
  // NEW
  line-height: 1em !important;
}
.mat-checkbox {
  margin-bottom: 0.4em !important;
}
.mat-dialog-container {
  overflow-x: hidden !important;
  max-height: 98vh !important;
  padding: 1em !important;
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.6) !important;
}
a.a-no-default {
  text-decoration: none;
  color: #000000;
  &:visited {
    color: #000000;
  }
  &:active {
    color: #000000;
  }
}
.radios {
  .mat-radio-button {
    //background: #f2f2f2;
    padding: 5px;
    margin-bottom: 0.5em;
    border-radius: 5px;
  }
  .mat-radio-button:hover {
    background: #e2e2e2;
    padding: 5px;
    margin-bottom: 0.5em;
    border-radius: 5px;
  }
  .mat-radio-label {
    white-space: normal !important;
    vertical-align: middle;
    text-align: left;
    font-size: 0.8em;
    .mat-radio-label-content {
      padding-left: 1em;
    }
  }
}
.odometer-value,
.odometer-formatting-mark {
  font-family: /* Poppins, */ Roboto, Raleway, Tahoma !important;
  font-weight: 100 !important;
}
.login-dialog {
  @media screen and (max-width: '500px') {
    width: 100%;
    height: 100%;
    mat-dialog-container {
      border-radius: 0px;
    }
  }
}
.slick-vertical .slick-slide {
  border: 0px !important;
  width: 100% !important;
}

.article img {
  max-width: 100% !important;
  margin: auto;
  text-align: center;
  display: block;
}

.article table {
  font-family: /* Poppins, */ Roboto, sans-serif;
}

.article table tr td {
  padding: 0.35em;
}

::placeholder {
  text-overflow: ellipsis !important;
}

[placeholder] {
  text-overflow: ellipsis !important;
}

.loadingInfo .mat-progress-spinner,
.loadingInfo .mat-progress-spinner svg {
  width: 40px !important;
  height: 40px !important;
}

.loadingInfo .mat-progress-spinner circle {
  stroke: #3ca896 !important;
}

.article a {
  font-weight: bold;
  border-bottom: 3px solid #3ca896;
  text-decoration: none;
  padding: 0 0.15em;
  transition: 0.2s all ease-out;
  position: relative;
  color: inherit;
  position: relative;
  background: transparent;
  z-index: 1;
}
.article a:before {
  content: '';
  position: absolute;
  background-color: #3ca896;
  bottom: 0;
  height: 3px;
  left: 0;
  top: 0;
  height: 100%;
  /* right: 0; */
  width: 0;
  transition: 0.2s all ease-out;
  z-index: -1;
}
.article a:hover:before,
a:visited:before {
  top: 0;
  height: 100%;
  width: 100%;
}

.lowercase {
  text-transform: lowercase;
}

.bt-card {
  background-color: white;
  position: relative;
}

.bt-shadowed {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.bt-rounded {
  border-radius: 7px !important;
  overflow: hidden !important;
}

.bt-button {
  border-radius: 18px !important;
  height: 36px !important;
  margin-top: 1em;
  font-weight: 700 !important;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none !important;
  text-overflow: ellipsis;
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  &.blue {
    color: white !important;
    background-color: #3ca896 !important;
  }
  &.fb {
    color: white !important;
    background-color: #4267b2 !important;
  }
  &.orange {
    color: white !important;
    background-color: #e8a30c !important;
  }
  &.dark {
    color: white !important;
    background-color: #3c3c3c !important;
  }
  &.pink {
    background: #dc3c50;
    color: white;
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
  &.light-button {
    background-color: gainsboro;
    border-color: gainsboro !important;
    color: grey;
  }
  &.bt-menu-button {
    background-color: rgba(125, 125, 125, 0.1);
  }
  &.red {
    background-color: #ff420f;
    color: white;
  }
  &.multi {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }
  &.multi-1 {
    margin-right: 0 !important;
    padding-right: 0.7em !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  &.multi-2 {
    margin-left: 0 !important;
    border-left: 2px solid rgba(0, 0, 0, 0.035);
    min-width: 0;
    padding-left: 0.4em !important;
    padding-right: 0.75em !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

ul.bt-no-style-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  clear: both;
  li {
    margin: 0;
    padding: 0.5em 0;
    clear: both;
    font-size: 1.3em;
    font-weight: 300;
  }
}

.bt-list-item-icon {
  display: block;
  float: left;
  width: 2em;
  color: #a2cd0a;
}

.bt-list-item-content {
  display: block;
  margin-left: 2.25em;
}

.bt-premium-badge {
  font-size: 0.6em;
  color: white;
  font-weight: bold;
  background-color: #ff420f;
  border-radius: 1em;
  height: 2em;
  padding: 0 0.5em;
  vertical-align: middle;
  margin-left: 0.5em;
}

::ng-deep .mat-badge-content {
  background-color: #dc3c50 !important;
}

.text-shadow {
  text-shadow: rgba(0, 0, 0, 0.8) 2px 2px 6px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
supported by Chrome, Opera and Firefox */
}

/* .snackbar-style {
background-color: white;
color: grey;
} */

.notifications-button .mat-button-wrapper {
  padding: 0 !important;
  display: inline-block !important;
  vertical-align: top !important;
  line-height: 28px !important;
}

.notifications-button .mat-badge-content {
  top: -4px !important;
  right: -4px !important;
  height: 15px !important;
  width: 15px !important;
  line-height: 15px !important;
  font-size: 9px !important;
  background: #3ca896 !important;
}

.notifications-menu {
  margin-top: 8px !important;
  overflow-y: auto !important;
  max-height: 400px !important;
  width: 380px !important;
  max-width: 100% !important;
  .mat-menu-content {
    padding: 0 !important;
  }
}

.shoppingCart-button .mat-badge-content {
  top: -7px !important;
  right: -4px !important;
  height: 15px !important;
  width: 15px !important;
  line-height: 15px !important;
  font-size: 9px !important;
  background: #dc3c50 !important;
}

.cdk-overlay-container {
  z-index: 998 !important;
}

.timepicker {
  z-index: 1500 !important;
}

span.premium-only {
  font-size: 0.45em;
  display: inline-block;
  height: 1.2em;
  background: #ff420f;
  vertical-align: middle;
  color: white;
  padding: 0.3em 0.7em;
  border-radius: 1em;
  margin: 0 0.3em;
}

.premium-icon {
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  background: #ff420f;
  border-radius: 7.5px;
  color: white;
  font-size: 8px;
  line-height: 12px;
  right: -2px;
  bottom: -2px;
  font-weight: 700;
}

.with-premium-icon:after {
  content: 'P';
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  background: red;
  border-radius: 7.5px;
  color: white;
  font-size: 8px;
  line-height: 12px;
  right: -2px;
  bottom: -2px;
  font-weight: 700;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: transparent !important;
}

.cc-revoke.cc-bottom {
  display: none;
}
